import React from 'react';
import styled from 'styled-components';
import HeroImage from '../../assets/HeroImage0.jpg';

const HeroContainer = styled.div`
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

const HeroText = styled.h1`
  color: #000; // Charcoal Grey
  text-align: center;
  padding: 20px;
  width: 50%;
  max-width: 800px;
  font-weight: 500;

  @media screen and (max-width: 768px) {
    width: 80%;
    font-size: 1.5em;
    }
  }
`;

const StyledHeroImage = styled.img`
    height: 400px; // Adjust as necessary
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;

    @media screen and (max-width: 768px) {
        height: 200px;
        }

`;



const HeroSection = () => {
  return (
    <HeroContainer>
      <StyledHeroImage src={HeroImage} alt="Hero Image" />
      <HeroText>
  Unlock the Future: Dive into Blockchain Development from 20+ Courses
</HeroText>

    </HeroContainer>
  )
}

export default HeroSection;
