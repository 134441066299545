import React from 'react'
import SearchBar from '../components/SearchBar'
import CoursesCatSubFilter from '../components/CoursesCatSubFilter'
import CourseListGird from '../components/CourseListGird'
import styled from 'styled-components'
import BottomNavBar from '../components/BottomNavBar'

const Container = styled.div`
    font-family: 'Outfit', sans-serif;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;



const Courses = [
    {
        id :1,
        name: 'Blockchain Fundamentals',
        desc: 'Learn the fundamentals of blockchain technology and how it will power the future of the internet.',
        totalNumberOfModules: 10,
    },
    {
        id: 2,
        name: 'Introduction to Cryptocurrencies',
        desc: 'Learn the basic of what is a cryptocurrency and how it works. You will dive into the history of Bitcoin, proof of work, proof of stake, and new consensus mechanisms.',
        totalNumberOfModules: 10,
        courseImg: 'https://images.unsplash.com/photo-1612837017391-4b9b7b0b9b0f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y2FyZWVyJTIwYmFja2dyb3VuZHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80'

    },
    {
        id: 3,
        name: 'Investing in Cryptocurrencies',
        desc: 'Learn how to invest in cryptocurrencies. Principles we will explore include dollar cost averaging, fundamental analysis, and technical analysis.',
        totalNumberOfModules: 10,
        courseImg: 'https://images.unsplash.com/photo-1612837017391-4b9b7b0b9b0f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y2FyZWVyJTIwYmFja2dyb3VuZHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80'

    },    
    {
        id: 4,
        name: 'Cryptocurrency Trading',
        desc: 'Learn how to trade cryptocurrencies on exchanges. We will explore the basics of technical analysis and how to use indicators to make trading decisions. We will also build a trading bot.',
        totalNumberOfModules: 10,
        courseImg: 'https://images.unsplash.com/photo-1612837017391-4b9b7b0b9b0f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y2FyZWVyJTIwYmFja2dyb3VuZHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80'

    },
    {
        id: 5,
        name: 'Introduction to Bitcoin',
        desc: 'Learn the basics of Bitcoin. We will explore the history of Bitcoin, how it works, and how it is different from traditional currencies.',
        totalNumberOfModules: 10,
        courseImg: 'https://images.unsplash.com/photo-1612837017391-4b9b7b0b9b0f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y2FyZWVyJTIwYmFja2dyb3VuZHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80'

    },
    {
        id: 6,
        name: 'Ethereum and Smart Contracts',
        desc: 'Learn about Ethereum and smart contracts. We will explore the history of Ethereum, how it works, and how it is different from Bitcoin. We will also explore solidity and build a smart contract.',
        totalNumberOfModules: 10,
        courseImg: 'https://images.unsplash.com/photo-1612837017391-4b9b7b0b9b0f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y2FyZWVyJTIwYmFja2dyb3VuZHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80'

    },
    {
        id: 7,
        name: 'Programming with Solidity',
        desc: 'Learn how to program with Solidity. We will explore the basics of Solidity and how to build smart contracts. We will have tests in an interactive development environment so you can write and test your code. This is an intermediate course.',
        totalNumberOfModules: 10,
        courseImg: 'https://images.unsplash.com/photo-1612837017391-4b9b7b0b9b0f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y2FyZWVyJTIwYmFja2dyb3VuZHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80'

    },
    {
        id: 8,
        name: 'Decentralized Finance (DeFi) Essentials',
        desc :'Exploring the DeFi ecosystem, understanding its components, and its impact on traditional finance.',
        totalNumberOfModules: 10,
        courseImg: 'https://images.unsplash.com/photo-1612837017391-4b9b7b0b9b0f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y2FyZWVyJTIwYmFja2dyb3VuZHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80'

    },
    {
        id:9,
        name:'Non-Fungible Tokens (NFTs) and Digital Art',
        desc:'Learn about NFTs and digital art. We will explore the history of NFTs, how they work, and how they are different from traditional currencies.',
        totalNumberOfModules: 10,
        courseImg: 'https://images.unsplash.com/photo-1612837017391-4b9b7b0b9b0f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y2FyZWVyJTIwYmFja2dyb3VuZHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80'

    },
    {
        id:10,
        name:'Cryptocurrency and Law',
        desc:'Learn about the legal aspects of cryptocurrencies. We will explore the history of cryptocurrencies, how they work, and how they are different from traditional currencies.',
        totalNumberOfModules: 10,
        courseImg: 'https://images.unsplash.com/photo-1612837017391-4b9b7b0b9b0f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y2FyZWVyJTIwYmFja2dyb3VuZHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80'

    },
    {
        id:11,
        name:'Security in Cryptocurrencies',
        desc:'Learn about the security aspects of cryptocurrencies. We will explore the history of cryptocurrencies, how they work, and how they are different from traditional currencies.',
        totalNumberOfModules: 10,
        courseImg: 'https://images.unsplash.com/photo-1612837017391-4b9b7b0b9b0f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y2FyZWVyJTIwYmFja2dyb3VuZHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80'

    },
]

const CoursesOverview = () => {

    const filterCoursesSetter = () => {
        console.log('filterCoursesSetter')
    }
  return (
    <Container>
        <SearchBar />
        <CoursesCatSubFilter courses={Courses} setFilteredCourses={filterCoursesSetter}/>
        <CourseListGird Courses={Courses} />
        <BottomNavBar />
    </Container>
  )
}

export default CoursesOverview