import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { marked } from "marked";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../Firebase";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet";
import LandingNav from "../components/LandingComponents/LandingNav";

// Configure the marked library to parse line breaks as in GitHub-flavored Markdown
marked.setOptions({
  breaks: true,
  gfm: true,
});

// You can configure DOMPurify to allow certain elements and attributes if they are being stripped out
DOMPurify.setConfig({
  ALLOWED_TAGS: [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "ul",
    "ol",
    "li",
    "strong",
    "em",
    "a",
    "blockquote",
    "code",
    "pre",
    "img",
  ],
  ALLOWED_ATTR: [
    "href",
    "align",
    "alt",
    "src",
    "title",
    "style",
    "class",
    "target",
  ],
});

const BlogContainer = styled.div`
  font-family: "Outfit", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333;
  padding: 20px;
  min-height: 100vh;
`;

const BlogTitle = styled.h1`
  color: #6b47cd;
  margin-bottom: 30px;

    @media screen and (max-width: 768px) {
    width: 90%;
    font-size: 1.8em;
    }
`;

const BlogContent = styled.div`
  width: 60%;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  line-height: 1.6; /* Improved line spacing for better readability */

  h2 {
    color: #6b47cd; /* Apply color to subheadings if needed */
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const { postId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, "Blogs", postId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setPost(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchData();
  }, [postId]);

  if (!post) return <div>Loading...</div>;

  // Convert the markdown text to HTML
  const formattedContent = marked(post.body || "");

  // Sanitize the HTML content
  const sanitizedContent = DOMPurify.sanitize(formattedContent);

  return (
    <div>
        <LandingNav />
    <BlogContainer>
      <Helmet>
        <title>{post.title} - My Blog</title> {/* Update the title tag */}
        <meta
          name="description"
          content={
            post.body
              ? `${post.body.substring(0, 150)}...`
              : "Default description"
          }
        />{" "}
        {/* Meta description tag using the post body */}
        {/* You can add more meta tags here if needed */}
      </Helmet>
      <BlogTitle>{post.title}</BlogTitle>
      <BlogContent dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
    </BlogContainer></div>
  );
};

export default BlogPost;
