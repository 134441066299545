import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  background-color: #6b47cd;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 1em;
  padding: 10px 20px;
  font-family: "Outfit", sans-serif;

  &:hover {
    background-color: #704cd2;
  }
`;

const Button = ({ text, onClick }) => {
  return (
    <StyledButton className="button" onClick={onClick}>
      {text}
    </StyledButton>
  );
};

export default Button;
