import React, { useState } from 'react';
import styled from 'styled-components';
import TextInput from './TextInput'; // Assuming this is a pre-made component
import SelectInput from './SelectInput'; // Assuming this is a pre-made component
import Button from './Button'; // Assuming this is a pre-made component
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../Firebase';
import { doc, updateDoc } from 'firebase/firestore';

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Heading = styled.h1`
  font-family: "Outfit", sans-serif;
  font-size: 26px;
  font-weight: 500;
  line-height: 40px;
`;

const CreateProfileForm = () => {
  const [interests, setInterests] = useState('');
  const [experienceLevel, setExperienceLevel] = useState('');
  const [bio, setBio] = useState('');
  const navigate = useNavigate();

  const handleSaveProfile = async (event) => {
    event.preventDefault();

    const userId = auth.currentUser.uid;

    const userDocRef = doc(db, "Users", userId);

    try {
      await updateDoc(userDocRef, {
        interests: interests,
        experienceLevel: experienceLevel,
        bio: bio,
      });

      navigate("/dashboard"); // Navigate to the dashboard or relevant page
    } catch (error) {
      console.error("Error updating user profile: ", error);
      alert("Failed to save profile");
    }
  };

  return (
    <StyledForm>
      <Heading>Create Your Profile</Heading>
      <TextInput
        placeholder="Interests (e.g., Trading, Blockchain Development)"
        onChange={(e) => setInterests(e.target.value)}
        value={interests}
      />
      <SelectInput
        options={[
          { value: 'beginner', label: 'Beginner' },
          { value: 'intermediate', label: 'Intermediate' },
          { value: 'expert', label: 'Expert' }
        ]}
        onChange={(e) => setExperienceLevel(e.target.value)}
        value={experienceLevel}
        placeholder="Experience Level"
      />
      <TextInput
        placeholder="Short Bio"
        onChange={(e) => setBio(e.target.value)}
        value={bio}
        isTextArea={true} // If TextInput can act as a textarea
      />
      <Button text="Save Profile" onClick={handleSaveProfile} />
    </StyledForm>
  );
};

export default CreateProfileForm;
