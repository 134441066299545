import React from 'react';
import styled from 'styled-components';

// Styled components
const CTAContainer = styled.div`
  text-align: center;
  padding: 40px 20px;
  margin-top: 30px;
  margin-bottom: 30px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

`;

const CTATitle = styled.h2`
  font-size: 2.5em;
  margin-bottom: 20px;

    @media screen and (max-width: 768px) {
        font-size: 1.5em;
    }
`;

const CTADescription = styled.p`
  font-size: 1.2em;
  margin-bottom: 20px;

    @media screen and (max-width: 768px) {
        font-size: 1em;
        width: 80%;
        margin-left: 10%;
    }
`;

const CTAButton = styled.button`
  background-color: #6b47cd;
  color: #fff;
  font-family: 'Outfit', sans-serif;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    
  background-color: #704cd2; 
  }
`;


const CTAComponent = () => {
  return (
    <CTAContainer>
      <CTATitle>Start Your Crypto Journey Today</CTATitle>
      <CTADescription>Join thousands of students learning about the exciting world of cryptocurrencies.</CTADescription>
      <CTAButton>Enroll Now</CTAButton>
    </CTAContainer>
  )
}

export default CTAComponent;
