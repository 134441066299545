import React from 'react'
import styled from 'styled-components'

const StyledLogo = styled.div`
    font-family: 'Outfit', sans-serif;
    font-size: 2em;
    font-weight: 600;
    color: #6B47CD;
    cursor: pointer;
`



const Logo = () => {
  return (
    <StyledLogo>
      BitBrainly
    </StyledLogo>
  )
}

export default Logo