import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../Firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  onSnapshot,
  setDoc,
} from "firebase/firestore";

const ChatroomContainer = styled.div`
  font-family: "Outfit", sans-serif;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
//   background-color: #e5ddd5;
  @media screen and (max-width: 768px) {
    height: 100vh;
  }
`;

const ChatsContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
  //   padding: 20px;
  display: flex;
  width: 100%;
  margin: 0;
  flex-direction: column;
//   background:pink;
  align-items: flex-start; /* Aligns messages to the start */
  @media screen and (max-width: 768px) {
    align-items: stretch;
  }
`;

const ChatroomTitle = styled.h3`
//   background-color: #6b47cd; 
  color: black;
  padding: 10px 20px;
  margin: 0;
  font-size: 1.6em;
  text-align: center;
  width: 100%;
`;

const Chat = styled.div`
  background-color: ${(props) => (props.isSender ? "#daccff" : "#e5ddd5")};
  padding: 10px 15px;
  width: fit-content;
  border-radius: 7.5px;
  max-width: 60%;
  align-self: ${(props) => (props.isSender ? "flex-end" : "flex-start")};
  margin: 12px;
`;


const ChatMessage = styled.p`
  font-size: 1.1em;
  word-wrap: break-word;
  margin: 5px 0;
`;

const ChatSender = styled.p`
  font-size: 0.9em;
  color: #6a6a6a;
  margin: 3px 0;
`;

const ChatInput = styled.input`
    flex-grow: 1;
  padding: 15px 20px;
  font-family: "Outfit", sans-serif;
  border-radius: 20px;
  margin: 0 10px;
  width: 100%;
  border:2px solid black;
`;

const ChatButton = styled.button`
  background-color: #6b47cd; 
  color: #fff;
  border: none;
  font-size: 1em;
  border-radius: 50%;
  width: 70px;
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin-right: 10px;
  font-family: "Outfit", sans-serif;
`;

const Flexer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px;
`;

const ChatTime = styled.p`
    font-size: 0.7em;
    color: #6a6a6a;
    margin: 3px 0;
`

const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 100px;
  width: 100%;
  padding: 10px;
  // background-color: blue;
`;

const ChatsCantGoBeyondThis = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    overflow-y: auto;
    max-height: 70%;
    padding: 20px;
    box-sizing: border-box;
    // background-color: red;
`

const Chatroom = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [chatroomMessages, setChatroomMessages] = useState([]);
  const [chatroomMessage, setChatroomMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, "Users", user.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          setUserDetails(userSnap.data()); // assuming name and email are stored in the Users collection
        }
      } else {
        navigate("/auth");
      }
    });

    return unsubscribeAuth;
  }, [navigate]);

  useEffect(() => {
    const chatroomRef = doc(db, "Chatrooms", "main");
    const unsubscribeChat = onSnapshot(chatroomRef, (snapshot) => {
      if (snapshot.exists()) {
        setChatroomMessages(snapshot.data().messages || []);
      }
    });

    return unsubscribeChat;
  }, []);

  const handleChatroomMessageChange = (e) => {
    setChatroomMessage(e.target.value);
  };

  const handleChatroomMessageSubmit = async (e) => {
    e.preventDefault();
    const chatroomRef = doc(db, "Chatrooms", "main");

    const newChatroomMessage = {
      sender: userDetails.name,
      message: chatroomMessage,
      timestamp: new Date(),
    };

    try {
      const docSnap = await getDoc(chatroomRef);
      if (docSnap.exists()) {
        // If the document exists, update it
        await updateDoc(chatroomRef, {
          messages: arrayUnion(newChatroomMessage),
        });
      } else {
        // If the document does not exist, create it
        await setDoc(chatroomRef, {
          messages: [newChatroomMessage],
        });
      }
      setChatroomMessage("");
    } catch (error) {
      console.error("Error handling the chat message: ", error);
    }
  };

  return (
    <ChatroomContainer>
      <ChatsContainer>
        <ChatroomTitle>Chatroom</ChatroomTitle>
        <ChatsCantGoBeyondThis>
        {chatroomMessages.map((chatroomMessage, index) => {
          // Convert timestamp to a readable date string
          const messageDate = chatroomMessage.timestamp.toDate();
          const displayDate = messageDate.toLocaleString(); // Example: you can format it as needed

          return (
            <Chat
              key={index}
              isSender={chatroomMessage.sender === userDetails.name}
            >
              <ChatMessage>{chatroomMessage.message}</ChatMessage>
              <ChatSender>{chatroomMessage.sender}</ChatSender>
              <ChatTime>{displayDate}</ChatTime> {/* Updated line */}
            </Chat>
          );
        })}
        </ChatsCantGoBeyondThis>
        <Form onSubmit={handleChatroomMessageSubmit}>
          <Flexer>
            <ChatInput
              type="text"
              placeholder="Enter your message"
              value={chatroomMessage}
              onChange={handleChatroomMessageChange}
            />
            <ChatButton type="submit">Send</ChatButton>
          </Flexer>
        </Form>
      </ChatsContainer>
    </ChatroomContainer>
  );
};

export default Chatroom;
