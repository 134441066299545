import React from 'react';
import styled from 'styled-components';
import interact from '../../assets/interact.png';
import expert from '../../assets/expert.png';
import certificate from '../../assets/certificate.png';

const FeaturesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px;
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 80%;
  }
`;

const Feature = styled.div`
  text-align: center;

  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
`;

const FeatureTitle = styled.h3`
  margin-bottom: 10px;
`;

const FeatureDescription = styled.p`
`;

const FeatureImage = styled.img`
    height: 100px;

`;

const FeaturesOverview = () => {
  return (
    <FeaturesContainer>
      <Feature>
        <FeatureImage src={interact} alt="Interactive Courses" />
        <FeatureTitle>Interactive Courses</FeatureTitle>
        <FeatureDescription>Engaging and dynamic course content.</FeatureDescription>
      </Feature>
      <Feature>
        <FeatureImage src={expert} alt="Expert Instructors" />
        <FeatureTitle>Expert Instructors</FeatureTitle>
        <FeatureDescription>Learn from industry leaders.</FeatureDescription>
      </Feature>
      <Feature>
        <FeatureImage src={certificate} alt="Certification" />
        <FeatureTitle>Certification</FeatureTitle>
        <FeatureDescription>Earn certificates verified by blockchain.</FeatureDescription>
      </Feature>
    </FeaturesContainer>
  )
}

export default FeaturesOverview;
