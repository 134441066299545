import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const SearchBarInput = styled.input`
    font-family: 'Outfit', sans-serif;
    width: 80%;
    height: 30px;

    border: 2px solid black;
    border-radius: 5px;
    padding: 10px;
    font-size: 1.2rem;
    margin: 20px;
    &:focus {
        outline: none;
    }
`;

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');


    return (
        <SearchBarInput type="text" placeholder="Search for a course" />
    )
}

export default SearchBar;