import React from "react";
import styled from "styled-components";
import AuthButton from "./AuthButton";
import { Link } from "react-router-dom";

// Navigation
const Nav = styled.nav`
  background-color: #fff;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

// Nav Item
const NavItem = styled(Link)`
  color: #003366; // Navy Blue
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 5px;

  &:hover {
    // background-color: #d9d9d9; // Light Grey
    // color: #003366; // Navy Blue
  }
`;

const NavItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 30%;
`;

// Nav Logo
const NavLogo = styled(Link)`
  font-size: 1.5em;
  font-weight: bold;
  color: #000;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
`;

const LandingNav = () => {
  return (
    <Nav>
      <NavLogo to="/">BitBrainly</NavLogo>
      {/* <NavItems>
        <NavItem href="/courses">Courses</NavItem>
        <NavItem href="/about">About</NavItem>
        <NavItem href="/blog">Blog</NavItem>

        </NavItems> */}
      {/* <NavItem href="/courses">Courses</NavItem>
      <NavItem href="/about">About</NavItem>
      <NavItem href="/blog">Blog</NavItem> */}
      <NavItems>
        <NavItem to="/blog">Blog</NavItem>
      </NavItems>
      <AuthButton />
    </Nav>
  );
};

export default LandingNav;
