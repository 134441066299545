import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

const CoursesCatSubFilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 82%;
    margin: 0 auto;
    margin-bottom: 2rem;
    align-items: center;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        padding: 0 2rem;
    }
    `;

const CoursesCatSubFilterSelect = styled.select`
    width: 90%;
    height: 50px;
    border: 2px solid black;
    border-radius: 5px;
    margin-bottom: 1rem;
    @media screen and (min-width: 768px) {
        width: 48%;
        margin-bottom: 0;
    }
    `;

const CoursesCatSubFilter = ({courses, setFilteredCourses}) => {
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');

    useEffect(() => {
        const categories = [...new Set(courses.map(course => course.category))];
        setCategories(categories);
    }, [courses]);

    useEffect(() => {
        if (selectedCategory) {
            const subCategories = [...new Set(courses.filter(course => course.category === selectedCategory).map(course => course.subCategory))];
            setSubCategories(subCategories);
        }
    }, [selectedCategory, courses]);

    useEffect(() => {
        if (selectedSubCategory) {
            const filteredCourses = courses.filter(course => course.category === selectedCategory && course.subCategory === selectedSubCategory);
            setFilteredCourses(filteredCourses);
        } else if (selectedCategory) {
            const filteredCourses = courses.filter(course => course.category === selectedCategory);
            setFilteredCourses(filteredCourses);
        } else {
            setFilteredCourses(courses);
        }
    }, [selectedCategory, selectedSubCategory, courses, setFilteredCourses]);

    return (
        <CoursesCatSubFilterContainer>
            <CoursesCatSubFilterSelect value={selectedCategory} onChange={e => setSelectedCategory(e.target.value)}>
                <option value="">All Categories</option>
                {categories.map(category => <option key={category} value={category}>{category}</option>)}
            </CoursesCatSubFilterSelect>
            <CoursesCatSubFilterSelect value={selectedSubCategory} onChange={e => setSelectedSubCategory(e.target.value)}>
                <option value="">All Sub-Categories</option>
                {subCategories.map(subCategory => <option key={subCategory} value={subCategory}>{subCategory}</option>)}
            </CoursesCatSubFilterSelect>
        </CoursesCatSubFilterContainer>
    )
}

export default CoursesCatSubFilter;