import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const UserCourseData = [
    {
        name: 'Introduction to Cryptocurrencies',
        totalNumberOfModules: 10,
        completedModules: 5,
    },
    {
        name: 'Blockchain Fundamentals',
        totalNumberOfModules: 10,
        completedModules: 3,
    },
]

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 120px;

    @media screen and (max-width: 768px) {
        width: 80%;
    }
`

const CourseProgressTrackerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const CourseProgressTrackerTitle = styled.h3`
    font-size: 2em;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
        font-size: 1.5em;
    }
`

const CourseProgressCourseTitle = styled.h4`
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 1.2em;
    }

`

const CourseProgressTrackerBar = styled.div`
    width: 100%;
    height: 30px;
    background-color: #ddd;

    display: flex;
    flex-direction: row;
    border-radius: 10px;



`

const CourseProgressTrackerBarFill = styled.div`
    width: ${props => props.width};
    height: 100%;
    background-color: #6b47cd;
    transition: width 1s ease-in-out;
    border-radius: 10px;

`

const CourseProgressTrackerPercentageLabel = styled.p`
    font-size: 1.2em;
    margin-left: 10px;
`

const Flexer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const CourseContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #eee;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    width: 80%;

    @media screen and (max-width: 768px) {
        width: 80%;
    }
`



const CourseProgressTracker = ({userId}) => {
    
        const [user, setUser] = useState(null);
        const navigate = useNavigate();
    
        // useEffect(() => {
        //     const getUser = async () => {
        //         const userDoc = await getDoc(doc(db, 'Users', userId));
        //         console.log(userId  );
        //         setUser(userDoc.data());
        //         console.log(userDoc.data());
        //     }
        //     if (userId) {
        //         getUser();
        //     }
        // }, [userId])


    
        return (
            <Container>
                <CourseProgressTrackerContainer>
                    <CourseProgressTrackerTitle>Course Progress</CourseProgressTrackerTitle>
                    {UserCourseData.map((course) => {
                        return (
                            <CourseContainer>
                                <CourseProgressCourseTitle>{course.name}</CourseProgressCourseTitle>
                                <Flexer>
                                <CourseProgressTrackerBar>
                                    <CourseProgressTrackerBarFill width={`${(course.completedModules / course.totalNumberOfModules) * 100}%`} />
                                </CourseProgressTrackerBar>
                                <CourseProgressTrackerPercentageLabel>{`${(course.completedModules / course.totalNumberOfModules) * 100}%`}</CourseProgressTrackerPercentageLabel>
                                </Flexer>
                            </CourseContainer>
                        )
                    })}
                </CourseProgressTrackerContainer>
            </Container>
        )
}

export default CourseProgressTracker