import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const WalletButton = styled.button`
  background-color: #6b47cd; 
  color: #FFFFFF; 
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  font-family: 'Outfit', sans-serif;

  &:hover {
    background-color: #704cd2; 
  }
`;

const AuthButton = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/auth');
  }

  return (
    <WalletButton onClick={handleClick}>Enter App</WalletButton>
  )
}

export default AuthButton;
