import React, { useState, useEffect } from 'react';
import { db } from '../Firebase';
import { doc, getDoc } from 'firebase/firestore';
import styled from 'styled-components';

const ProfileContainer = styled.div`
  font-family: "Outfit", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
//   height: 100vh;
//   background-color: #e5ddd5; /* Same as Chat background */
`;

const ProfileTitle = styled.h1`
  color: #6b47cd; /* Similar to ChatButton color */
  margin-bottom: 20px;
`;

const ProfileLabel = styled.p`
  font-size: 1em;
  color: #333;
  margin: 5px 0;
  text-transform: uppercase;
`;

const ProfileValue = styled.p`
    font-size: 1.2em;
    color: #333;
    margin: 5px 0;
    // font-weight: bold;
    margin-bottom: 20px;
`;

const ProfileView = ({ userId }) => {
  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    const fetchProfileData = async () => {
      const docRef = doc(db, "Users", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setProfileData(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchProfileData();
  }, [userId]);

  return (
    // <div>
    //   <h1>Profile</h1>
    //   <p>Name: {profileData.name}</p>
    //   <p>Email: {profileData.email}</p>
    //   {/* Add more fields as needed */}
    // </div>
    <ProfileContainer>
      <ProfileTitle>Profile</ProfileTitle>
      {/* <ProfileInfo>Name: {profileData.name}</ProfileInfo>
      <ProfileInfo>Email: {profileData.email}</ProfileInfo> */}
        <ProfileLabel>Name</ProfileLabel>
        <ProfileValue>{profileData.name}</ProfileValue>
        <ProfileLabel>Email</ProfileLabel>
        <ProfileValue>{profileData.email}</ProfileValue>

      {/* Add more fields as needed */}
    </ProfileContainer>
  );
};

export default ProfileView;
