import React, { useState, useEffect } from "react";
import { db } from "../Firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import styled from "styled-components";

const EditProfileContainer = styled.div`
  font-family: "Outfit", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
//   height: 100vh;
//   background-color: #e5ddd5; /* Same as Chat background */
`;

const EditProfileTitle = styled.h1`
  color: #6b47cd; /* Similar to ChatButton color */
  margin-bottom: 20px;
`;

const EditProfileForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const EditProfileLabel = styled.label`
  font-size: 1em;
  margin: 10px 0;
  text-transform: uppercase;
`;

const EditProfileInput = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  width: 50%;
  font-family: "Outfit", sans-serif;
  font-size: 1em;
  border-radius: 5px;
  border: 2px solid #6b47cd; /* Similar to ChatButton color */

  @media screen and (max-width: 768px) {
    width: 80%;
  }
`;

const EditProfileButton = styled.button`
  background-color: #6b47cd; /* Similar to ChatButton color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
`;


const EditProfileView = ({ userId }) => {
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    // Add other fields as necessary
  });

  useEffect(() => {
    const fetchProfileData = async () => {
      const docRef = doc(db, "Users", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setProfileData(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchProfileData();
  }, [userId]);

  const handleChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = doc(db, "Users", userId);
    await updateDoc(docRef, profileData);
    alert("Profile updated successfully!");
  };

  return (
    // <div>
    //   <h1>Edit Profile</h1>
    //   <form onSubmit={handleSubmit}>
    //     <label>Name:</label>
    //     <input
    //       name="name"
    //       type="text"
    //       value={profileData.name}
    //       onChange={handleChange}
    //     />
    //     <br />
    //     <label>Email:</label>
    //     <input
    //       name="email"
    //       type="email"
    //       value={profileData.email}
    //       onChange={handleChange}
    //     />
    //     {/* Add more input fields as necessary */}
    //     <br />
    //     <button type="submit">Save Changes</button>
    //   </form>
    // </div>
    <EditProfileContainer>
      <EditProfileTitle>Edit Profile</EditProfileTitle>
      <EditProfileForm onSubmit={handleSubmit}>
        <EditProfileLabel>Name</EditProfileLabel>
        <EditProfileInput
          name="name"
          type="text"
          value={profileData.name}
          onChange={handleChange}
        />
        <EditProfileLabel>Email</EditProfileLabel>
        <EditProfileInput
          name="email"
          type="email"
          value={profileData.email}
          onChange={handleChange}
        />
        {/* Add more input fields as necessary */}
        <EditProfileButton type="submit">Save Changes</EditProfileButton>
      </EditProfileForm>
    </EditProfileContainer>
  );
};

export default EditProfileView;
