import React from 'react'
import Chatroom from '../components/Chatroom'
import styled from 'styled-components'
import BottomNavBar from '../components/BottomNavBar';

const Container = styled.div`
    font-family: 'Outfit', sans-serif;
    width: 100vw;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;


const CommunityForum = () => {
  return (
    <Container>
        <Chatroom />
        <BottomNavBar />
    </Container>
  )
}

export default CommunityForum