import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import CourseCard from './CourseCard'


const CourseGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 120px;

    @media screen and (max-width: 1084px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        // background: red;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
`



const CourseListGird = ({Courses}) => {
  return (
    <CourseGrid>
        {Courses.map((course) => (
            <CourseCard key={course.id} course={course} />
        ))}
    </CourseGrid>
  )
}

export default CourseListGird