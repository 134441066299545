import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../Firebase'; // Adjust this import as per your Firebase configuration file
import styled from 'styled-components';
import LandingNav from '../components/LandingComponents/LandingNav';

const BlogContainer = styled.div`
    font-family: 'Outfit', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    color: #333;
    padding: 20px;
    min-height: 100vh;
`;

const BlogHeading = styled.h1`
    color: #6b47cd; /* Color similar to Button */
    margin-bottom: 30px;
`;

const BlogCard = styled(Link)`
    width: 60%;
    background-color: #ededed;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    text-decoration: none;
    color: #333;
    `

    const PostTitle = styled.h2`
    margin-bottom: 20px;
`;

const PostBody = styled.p`
    margin-bottom: 20px;
`;



const BlogList = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, 'Blogs'));
      setPosts(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };

    fetchData();
  }, []);

  return (
    // <div>
    //   {posts.map(post => (
    //     <div key={post.id}>
    //       <Link to={`/blog/${post.id}`}>
    //         <h2>{post.title}</h2>
    //         <p>{`${post.body.substring(0, 150)}...`}</p>
    //       </Link>
    //     </div>
    //   ))}
    // </div>
    <div>
    <LandingNav />
    <BlogContainer>
      <BlogHeading>Blog Posts</BlogHeading>
      {posts.map(post => (
        <BlogCard to={`/blog/${post.id}`}>
          <PostTitle>{post.title}</PostTitle>
          <PostBody>{`${post.body.substring(0, 150)}...`}</PostBody>
        </BlogCard>
      ))}
    </BlogContainer></div>
  );
};

export default BlogList;
