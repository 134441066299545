import React, { useState } from "react";
import styled from "styled-components";
import LoginForm from "../components/LoginForm";
import SignupForm from "../components/SignUpForm";
import LandingNav from "../components/LandingComponents/LandingNav";

const SwitchButton = styled.button`
  background-color: transparent;
  border: 0;
  color: #111;
  cursor: pointer;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 15px;
  padding: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
  margin-top: -80px;
`;

const Authentication = () => {
  const [isLogin, setIsLogin] = useState(true); // true for login, false for signup

  const toggleAuthMode = () => {
    setIsLogin(!isLogin);
  };

  return (
    <div>
      <LandingNav />
      <Container>
        {isLogin ? <LoginForm /> : <SignupForm />}
        <SwitchButton onClick={toggleAuthMode}>
          {isLogin
            ? "Don't have an account? Sign up"
            : "Already have an account? Log in"}
        </SwitchButton>
      </Container>
    </div>
  );
};

export default Authentication;
