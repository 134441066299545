import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { db } from "../Firebase";
import { doc, getDoc } from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";

const WelcomeOverviewContainer = styled.div`
  font-family: "Outfit", sans-serif;
  align-items: center;
  width: 100%;
//   background-color: pink;
margin-top: 50px;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: 80%;
  }
`;

const WelcomeOverviewTitle = styled.h1`
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
`;

const WelcomeOverviewDescription = styled.p`
  font-size: 1.2em;
  margin-bottom: 20px;
`;

const WelcomeOverviewButton = styled.button`
  background-color: #6b47cd;
  color: #fff;

  font-family: "Outfit", sans-serif;
  border: none;
  padding: 10px 20px;
  font-size: 1em;

  border-radius: 5px;
  cursor: pointer;
  margin: 20px;

  &:hover {
    background-color: #704cd2;
  }
`;

const Flexer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const WelcomeOverview = ({ userId }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getUser = async () => {
      const userDoc = await getDoc(doc(db, "Users", userId));
      console.log(userId);
      setUser(userDoc.data());
      console.log(userDoc.data());
    };
    if (userId) {
      getUser();
    }
  }, [userId]);

  const handleSignOut = () => {
    navigate("/");
  };

  const logUser = () => {
    console.log(user);
  };

  return (
    <WelcomeOverviewContainer>
      <WelcomeOverviewTitle>
        Welcome to BitBrainly, {user?.name}!
      </WelcomeOverviewTitle>
      <WelcomeOverviewDescription>
        Here is a quick overview of your progress
      </WelcomeOverviewDescription>
      {/* <Flexer>
        <WelcomeOverviewButton onClick={logUser}>
          Log User
        </WelcomeOverviewButton>
        <WelcomeOverviewButton onClick={handleSignOut}>
          Sign Out
        </WelcomeOverviewButton>
      </Flexer> */}
    </WelcomeOverviewContainer>
  );
};

export default WelcomeOverview;
