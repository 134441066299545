import React, {useState} from 'react'
import styled from 'styled-components'

const CourseCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Outfit', sans-serif;
  width: 300px;
//   height: 300px;
  border:2px solid black;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
    align-items: center;
    justify-content: center;
`

const CourseCardTitle = styled.h1`
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 1.2em;
    }

`

const CourseCardDescription = styled.p`
    font-size: 1.1em;
    margin-bottom: 20px;
    padding: 10px;
`

const CourseCardButton = styled.button`
    background-color: #6b47cd;
    color: #fff;

    font-family: 'Outfit', sans-serif;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    border-radius: 5px;
    cursor: pointer;
    margin: 20px;

    &:hover {
        background-color: #704cd2;
    }

`

const CourseImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`

const CourseCard = ({course}) => {
    const [courseImage, setCourseImage] = useState(null)
    
    return (
        <CourseCardContainer>
        {/* <CourseImage src={course.courseImg} /> */}
        <CourseCardTitle>{course.name}</CourseCardTitle>
        <CourseCardDescription>{course.desc}</CourseCardDescription>
        <CourseCardButton>Start Course</CourseCardButton>
        </CourseCardContainer>
    )
}

export default CourseCard