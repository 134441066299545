import React from 'react';
import LandingNav from '../components/LandingComponents/LandingNav';
import HeroSection from '../components/LandingComponents/HeroSection';
import FeaturesOverview from '../components/LandingComponents/FeaturesOverview';
import PopularCoursesPreview from '../components/LandingComponents/PopularCoursesPreview';
import CTAComponent from '../components/LandingComponents/CTAComponent';
import LandingFooter from '../components/LandingComponents/LandingFooter';
import styled from 'styled-components';

const LandingContainer = styled.div`
font-family: 'Outfit', sans-serif;
`;

const LandingPage = () => {
  return (
    <LandingContainer>
      <LandingNav />
      <HeroSection />
      <FeaturesOverview />
      <PopularCoursesPreview />
      <CTAComponent />
      <LandingFooter />
    </LandingContainer>
  )
}

export default LandingPage;
