import React from "react";
import styled from "styled-components";

// Styled components
const FooterContainer = styled.footer`
  padding: 30px;
  text-align: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const FooterLinks = styled.div`
  margin-bottom: 20px;
`;

const FooterLink = styled.a`
  color: #0e0e0e;
  margin: 0 10px;
  text-decoration: none;

  &:hover {
    color: #000000; // Metallic Silver
  }

  &:visited {
    color: #000; // Charcoal Grey
  }

  @media screen and (max-width: 768px) {
    margin: 5px;
  }
`;

const FooterText = styled.p`
  font-size: 0.8em;
`;

const LandingFooter = () => {
  return (
    <FooterContainer>
      <FooterLinks>
        <FooterLink href="#">About Us</FooterLink>
        <FooterLink href="#">Courses</FooterLink>
        <FooterLink href="#">Contact</FooterLink>
        <FooterLink href="#">Terms of Service</FooterLink>
        <FooterLink href="#">Privacy Policy</FooterLink>
      </FooterLinks>
      <FooterText>© 2024 BitBrainly.com. All rights reserved.</FooterText>
    </FooterContainer>
  );
};

export default LandingFooter;
