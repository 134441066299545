import React from 'react';
import LandingPage from './pages/LandingPage';
import Authentication from './pages/Authentication';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CreateProfile from './pages/CreateProfile';
import UserDashboard from './pages/UserDashboard';
import CoursesOverview from './pages/CoursesOverview';
import CommunityForum from './pages/CommunityForum';
import ProfileSettings from './pages/ProfileSettings';
import Blog from './pages/Blog';
import BlogList from './pages/BlogList';
import BlogPost from './pages/BlogPost';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/auth" element={<Authentication />} />
        <Route path="/create-profile" element={<CreateProfile />} />
        <Route path="/dashboard" element={<UserDashboard />} />
        <Route path="/courses" element={<CoursesOverview />} />
        <Route path="/forum" element={<CommunityForum />} />
        <Route path="/profile" element={<ProfileSettings />} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/blog/:postId" element={<BlogPost />} />
      </Routes>
    </Router>
  );
}

export default App;
