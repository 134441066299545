import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { auth } from '../Firebase';
import ProfileView from '../components/ProfileView';
import EditProfileView from '../components/EditProfileView';
import BottomNavBar from '../components/BottomNavBar';

const Button = styled.button`
  background-color: #6b47cd; /* Primary color, similar to ChatButton */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px; /* Rounded corners for a modern look */
  font-size: 1em;
  outline: none; 
  font-family: 'Outfit', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    background-color: #8057e7; /* Slightly lighter on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  }
`;


const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Outfit', sans-serif;
    width: 100vw;
    height: 100vh;

    @media screen and (max-width: 768px) {
        height: 100vh;
    }
    `;
const ProfileSettings = () => {
    const [editMode, setEditMode] = useState(false);
    const [userId, setUserId] = useState(null);
  
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        if (user) {
          setUserId(user.uid);
        } else {
          // User is not signed in
          // Handle this case (redirect to login page or show a message)
        }
      });
  
      // Cleanup subscription on unmount
      return () => unsubscribe();
    }, []);
  
    // Return null or loading state if userId is not set yet
    if (!userId) {
      return <div>Loading...</div>; // Or any other loading state
    }
  
    return (
      <Container>
        <Button onClick={() => setEditMode(!editMode)}>
          {editMode ? 'View Profile' : 'Edit Profile'}
        </Button>
        {editMode ? (
          <EditProfileView userId={userId} />
        ) : (
          <ProfileView userId={userId} />
        )}
        <BottomNavBar />
      </Container>
    );
  };
  
  export default ProfileSettings;
