import React from 'react';
import styled from 'styled-components';

// Courses Container
const CoursesContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

// Course Card
const CourseCard = styled.div`
  width: 300px;
  margin: 15px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #FFFFFF; // White
  text-align: center;
`;

// Course Title
const CourseTitle = styled.h3`
  margin-bottom: 10px;
`;

// Course Description
const CourseDescription = styled.p`
  color: #666;
`;

// Heading
const Heading = styled.h2`
  text-align: center;
`;

// Flexer
const Flexer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;


    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const PopularCoursesPreview = () => {
  // Example data
  const courses = [
    { title: 'Introduction to Cryptocurrencies', description: 'Learn the basics of digital currencies and how they operate.' },
    { title: 'Blockchain Fundamentals', description: 'Dive deep into the technology behind the blockchain.' },
    { title: 'Investing in Crypto', description: 'Strategies for successful cryptocurrency investments.' },
    // Add more courses as needed
  ];

  return (
    <CoursesContainer>
        <Heading>Popular Courses</Heading>
        <Flexer>
      {courses.map((course, index) => (
        <CourseCard key={index}>
          <CourseTitle>{course.title}</CourseTitle>
          <CourseDescription>{course.description}</CourseDescription>
        </CourseCard>
      ))}
      </Flexer>
    </CoursesContainer>
  )
}

export default PopularCoursesPreview;
