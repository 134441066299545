import React from 'react'
import CreateProfileForm from '../components/CreateProfileForm'

const CreateProfile = () => {
  return (
    <div>
        <CreateProfileForm  />
    </div>
  )
}

export default CreateProfile