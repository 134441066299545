import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { auth } from '../Firebase';
import { useNavigate } from 'react-router-dom';
import WelcomeOverview from '../components/WelcomeOverview';
import CourseProgressTracker from '../components/CourseProgressTracker';
import BottomNavBar from '../components/BottomNavBar';

const Container = styled.div`
    font-family: 'Outfit', sans-serif;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;


const UserDashboard = () => {

    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(userAuth => {
            if (userAuth) {
                setUserId(userAuth.uid);
            } else {
                setUserId(null);
            }
        });
        return unsubscribe;
    }
    , []);

    return (
        <Container>
            <WelcomeOverview userId={userId} />
            <CourseProgressTracker userId={userId} />
            <BottomNavBar />
        </Container>
    )
}

export default UserDashboard;